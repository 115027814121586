import { atom } from "recoil";

export const themeState=atom({
    key:"theme",
    default:"light"
})
export const nowUserState=atom({
    key:"nowUser",
    default:null
})
export const authTest=atom({
    key:"test",
    default:null
})
