import React, { useEffect, useRef, useState } from 'react';
import { bgColor } from '../const/const';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import ShareBtn from '../components/ShareBtn';
import EmptyBg from '../images/empty-wide-bg.png'
import WideEmptyBg from '../images/empty-wide-bg.png'
import { IoArrowDown } from "react-icons/io5";
import CustomFooter from "../components/CustomFooter"
import { Mobile, PC } from '../components/ReactiveLayout';
import { CgClose } from 'react-icons/cg';
import KakaoChannelAddBtn from '../components/KakaoChannelAddBtn';
import html2canvas from 'html2canvas';
import { exportComponentAsJPEG } from 'react-component-export-image';
import SaveImgBtn from './SaveImgBtn';
const ComponentToPrint = React.forwardRef((props, ref) =>
    
    
    {
        const typographyRef = useRef(null);
        const [height,setHeight]=useState(null)

        useEffect(() => {
            if (typographyRef.current) {
                // Typography의 높이를 측정하여 props.innerHeight에 할당합니다.
                const typographyHeight = typographyRef.current.offsetHeight;
                setHeight(typographyHeight);
            }
        }, []);
        
        return(
    // 1920 945

    <div ref={ref} style={{ marginTop: -(height+500),  
    height: (height+500),
    backgroundImage:`url(${WideEmptyBg})`,
    backgroundRepeat: "no-repeat", // 이미지 반복 제거
    backgroundSize: "cover", // 이미지를 컨테이너에 꽉 차도록 설정
    display:"flex",
    flexDirection:"column",
    alignItems: "center",
    justifyContent: "center",
    }}>
      <div
      
                        style={{
                            display: "flex",
                            marginTop:"30px",
                            gap: "30px",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            width:  props.type==="mb"?"300px":"500px",
                           height:"100%",
                            // height: `calc(100vh - 130px - 30px - 58px - 30px)`,
                           
                    //          // backgroundColor:"red",
                            overflowY: "scroll",
                            zIndex: 999,

                        }}
                    >
                        <div
                        style={{
                            height:"30px"
                        }}
                        />
                       <Typography 
                    ref={typographyRef} 
                       style={{ color: "white",textAlign:"start"}}>
                     {props.converted}</Typography>
                     
                  
                     {props.openDialog&&   <div
                            className='glass'
                            style={{
                               
                                width:  props.type==="mb"?"300px":"480px",
                                fontSize: 16,
                                padding: "16px",
                                fontWeight: 700,
                                color: "white",
                                display: "flex",
                                justifyContent:"center",
                                alignItems:"center",
                                flexDirection: "column",
                                gap: "8px",
                                marginBottom:"30px"

                            }}
                        >
                            <div
                            style={{
                                width:"100%",
                                display:"flex",
                                flexDirection:"row",
                                justifyContent:"flex-end"
                            }}
                            > <button
                                style={{
                                    border:"1px solid white",
                                    color:"white",
                                    backgroundColor:"transparent"
                                }}
                                  
                                ><CgClose/></button></div>
 
                            <span>디지털 세상에서 함께 성장하고싶은 친구에게도 공유해보세요!</span>
                            <div
                                style={
                                    {
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between"
                                    }
                                }
                            >
                                <div><IoArrowDown size={20} />
                                    <IoArrowDown size={20} />
                                    <IoArrowDown size={20} /></div>

                              
                            </div>

                        </div>}
                        <ShareBtn
                        width={props.type==="mb"?"300px":"500px"}
                        />
                        {/* <div style={{
                            height:"10px"
                        }}/> */}
                        <SaveImgBtn
                         width={props.type==="mb"?"300px":"500px"}
                         
                         />
                          <div style={{
                            height:"30px"
                        }}/>
                   </div>
        {/* 푸터 */}
        <div
        style={{
        
       
            width: props.innerWidth,
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",borderTop: "2px solid white",
        
        }}
        >
        <ul
            style={{
                width: props.innerWidth <= 800 ? "381px" : "1329px",
                //  backgroundColor:"white",
                height: "90px",
                margin: 0,
                padding: "8px",
                // paddingLeft:"100px",
                listStyle: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                textAlign: "left",
                fontSize: "11px",
                gap: "3px",
                
                color: "white"
        
        
            }}
        >
            <li
            style={{
                // width:"100%",
                // display:"flex",
                // flexDirection:"row",
                // alignItems:"flex-start",
                // justifyContent:"flex-start"
            }}
            >회사명 : 디엠컴퍼니 | 대표이사 : 김미수 | 카카오톡채널:디지털다능인</li>
            <li></li>
            <li>주소 : 서울특별시 서초구 사임당로8길 13, 402동 4층 J123호(서초동, 제일빌딩)</li>
            <li>사업자 등록번호 : 187-40-01020</li>
        
        </ul>
        
        </div>
    </div>
  )});
function ResultPage(props) {
    const componentRef = useRef();
    // const location = useLocation()
    const [innerHeight, setInnerHeight] = useState(window.innerHeight);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [openDialog, setOpenDialog] = useState(true)
    const handleResize = () => {
        setInnerHeight(window.innerHeight);
        setInnerWidth(window.innerWidth);


    };
    const handleDownloadImage = async () => {
        const element = document.getElementById('share-img');
        // element.append(`<div>${converted}</div>`)
//   console.log("el",element)  
        html2canvas(element
            , {
            
            onclone: (clonedDoc) => {
                // Find the element in the cloned document
       clonedDoc.getElementById('share-img').style.display="block"
    
                // Modify the opacity of the cloned element to make it visible
                // if (clonedElement) {
                //     clonedElement.style.opacity = 1;
                // }
            }
        }
    ).then((canvas) => {
            const data = canvas.toDataURL('image/jpeg');
            const link = document.createElement('a');
            // link.style.opacity=1;
            link.href = data;
            link.download = 'downloaded-image.jpg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };
   
    useEffect(() => {

        window.addEventListener("resize", handleResize);

        return () => {
            // cleanup
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    function addPercentageBeforeNumbers(inputStr) {
         let result = ''; 
         if (inputStr!=null) {
             for (let i = 0; i < inputStr.length; i++) {
            if (!isNaN(inputStr[i])) {
                result += '%' + inputStr[i];
            } else {
                result += inputStr[i];
            }
        }
        }
        return result;
    }
    function convertToParagraph(inputStr) {
        let result = inputStr.split('%').map((txt, index) => {
            if (isNaN(parseInt(txt))) {
                return <>{txt}</>;
             } else {
                return <><p/>{txt} </>;
            }
        })
        return result;
    }
  
    const resultString = addPercentageBeforeNumbers(localStorage.getItem("result"));
    const converted=convertToParagraph(resultString)
    return (

        <div>
          
            <Mobile>
            <ComponentToPrint 
            type={"mb"}
  ref={componentRef} 
  converted={converted} 
  innerWidth={innerWidth} 
  innerHeight={innerHeight}
  openDialog={openDialog}
  />
            <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                   
                    }}
                >
                    <img
                        id='bg-img'
                        style={{
                            position: "absolute",
                            top: 0,
                            left: `calc(${innerWidth} / 2)`,
                            width: innerWidth,
                            height: innerHeight,
                            // objectFit: "cover" // 이미지 비율 유지를 위해 추가
                        }}
                        src={ WideEmptyBg}
                    />
 <div
                        style={{
                            display: "flex",
                            marginTop:"30px",
                            // gap: "30px",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            width:  "330px",
                           
                            height: openDialog?`calc(100vh - 90px - 32px - 130px - 160px - 30px - 58px - 30px - 50px)`:`calc(100vh - 130px - 30px - 58px - 30px - 30px - 58px - 30px)`,//openDialog?"450px":"570px",
                           
                    //          // backgroundColor:"red",
                            overflowY: "scroll",
                            zIndex: 999,

                        }}
                    >
                        
                        <Typography sx={{ color: "white", textAlign: "start" }}>
                            {/* {location.state.result.split(".").map(txt=><p
                                    style={{
                                        fontSize:"14px"
                                    }}
                                    >{txt}</p>)} //location.state.result.*/}
                            {/* {
                            tempTxt.split(".").map((txt, index) => {
                                if (isNaN(parseInt(txt))) {
                                    console.log("txt가 글자>>>", txt)
                                    return <>{txt}.<p
                                        style={{
                                            paddingBottom: "0.1px"
                                        }}
                                    /></>;
                                 } else {
                                    console.log("txt가 숫자>>>", parseInt(txt))
                                    return txt + ".";
                                }
                            })} */}
                          {converted}
                         
                 

                        </Typography>
                     {openDialog&&   <div
                            className='glass'
                            style={{
                                position: "absolute",
                                width:  "300px",
                                bottom: "calc(130px + 58px + 30px + 30px + 58px)",
                                fontSize: 16,
                                padding: "16px",
                                fontWeight: 700,
                                color: "white",
                                display: "flex",
                                justifyContent:"center",
                                alignItems:"center",
                                flexDirection: "column",
                                gap: "8px",
                                height:"160px"
                           

                            }}
                        ><div
                        style={{
                            width:"100%",
                            display:"flex",
                            flexDirection:"row",
                            justifyContent:"flex-end",
                            fontSize:"20px"
                        }}
                        > <button
                            style={{
                                border:"1px solid white",
                                color:"white",
                                backgroundColor:"transparent"
                            }}
                                onClick={() => {
                                    setOpenDialog(false)
                                 }}
                            ><CgClose/></button></div>

                            <span
                            style={{
                                 fontSize:"20px"
                            }}
                            >함께 성장하고 싶은</span>
                            <span
                            style={{
                                 fontSize:"20px"
                            }}
                            >친구에게 공유하기</span>
                            <div
                                style={
                                    {
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between"
                                    }
                                }
                            >
                                <div><IoArrowDown size={20} />
                                    <IoArrowDown size={20} />
                                    <IoArrowDown size={20} /></div>

                              
                            </div>

                        </div>}
                        <div
                            style={{
                                position: "absolute",
                                bottom: "130px",display:"flex",
                                flexDirection:"column",
                                gap:"30px"
                            }}
                        >
                            <ShareBtn
                            width={"300px"}
                            />
                             <SaveImgBtn
                         width={"300px"}
                         onClick={() => exportComponentAsJPEG(componentRef,{
                            fileName:"직업추천_결과"
                         })}
                         />

                        </div>
                    </div>
                </div>
            </Mobile>
            <PC
            
            >
              {/* <div id="capture">
  ...
</div> */}
               {/* <div
id='share-img'
style={{
    display:"none",
    // opacity:0,
    // visibility:"hidden",
    // height: "0%",
    // overflow:"hidden",
    left:"30%",
    position:"absolute",
    height:"500px",
    backgroundColor:"pink",
    width:"500px",
    zIndex:999
}}
>{converted}</div> */}
  <ComponentToPrint 
  ref={componentRef} 
  converted={converted} 
  innerWidth={innerWidth} 
  innerHeight={innerHeight}
  openDialog={openDialog}
  />

                <div
             
                    style={{
         
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                   
                    }}
                >
                    <img
                        id='bg-img'
                        style={{
                            position: "absolute",
                            top: 0,
                            left: `calc(${innerWidth} / 2)`,
                            width: innerWidth,
                            height: innerHeight,
                            // objectFit: "cover" // 이미지 비율 유지를 위해 추가
                        }}
                        src={ WideEmptyBg}
                    />

                   
 <div
                        style={{
                            display: "flex",
                            marginTop:"30px",
                            // gap: "30px",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            width:  "500px",
                           
                            height: openDialog?`calc(100vh - 130px - 30px - 58px - 30px - 150px - 58px - 30px)`:`calc(100vh - 130px - 30px - 58px - 30px - 58px - 30px - 30px)`,
                           
                    //          // backgroundColor:"red",
                            overflowY: "scroll",
                            zIndex: 999,

                        }}
                    >
                        
                        <Typography  sx={{ color: "white", textAlign: "start" }}>
                        {converted}
                        </Typography>
                     {openDialog&&   <div
                            className='glass'
                            style={{
                                position: "absolute",
                                width:  "500px",
                                bottom: "calc(130px + 58px + 30px + 58px + 30px)",
                                fontSize: 16,
                                padding: "16px",
                                fontWeight: 700,
                                color: "white",
                                display: "flex",
                                justifyContent:"center",
                                alignItems:"center",
                                flexDirection: "column",
                                gap: "8px",
                           

                            }}
                        >
                            <div
                            style={{
                                width:"100%",
                                display:"flex",
                                flexDirection:"row",
                                justifyContent:"flex-end"
                            }}
                            > <button
                                style={{
                                    border:"1px solid white",
                                    color:"white",
                                    backgroundColor:"transparent"
                                }}
                                    onClick={() => {
                                        setOpenDialog(false)
                                     }}
                                ><CgClose/></button></div>
 
                            <span>디지털 세상에서 함께 성장하고싶은 친구에게도 공유해보세요!</span>
                            <div
                                style={
                                    {
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between"
                                    }
                                }
                            >
                                <div><IoArrowDown size={20} />
                                    <IoArrowDown size={20} />
                                    <IoArrowDown size={20} /></div>

                              
                            </div>

                        </div>}
                        <div
                            style={{
                                position: "absolute",
                                bottom: "130px",
                                display:"flex",
                                flexDirection:"column",
                                gap:"30px"
                            }}
                        >
                            <ShareBtn width={"500px"} />
                         <SaveImgBtn
                         width={"500px"}
                         onClick={() => exportComponentAsJPEG(componentRef,{
                            fileName:"직업추천_결과"
                         })}
                         />
                            {/* <KakaoChannelAddBtn/> */}
                        </div>
                    </div>
                </div>
            </PC>
        </div>
        
    );
}

export default ResultPage;