import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';
import { RouterProvider, createBrowserRouter, useLocation } from 'react-router-dom';
import CustomLayout from './pages/CustomLayout';
import LoginPage from './pages/LoginPage';
import AnswerPage from './pages/AnswerPage';
import LoadingPage from './pages/LoadingPage';
import ResultPage from './pages/ResultPage';
import PreNotiPage from './pages/PreNotiPage';
import GoogleInfoPage from './pages/GoogleInfoPage';
import KakaoInfoPage from './pages/KakaoInfoPage';
// var useragt = navigator.userAgent.toLowerCase();

const router = createBrowserRouter(

  [
  //첫페이지->로그인
  { 
    element: <LoginPage/>, path: "/" },
 
{
  element:<CustomLayout />,
  //내부 화면만 교체
  children:[
   
    {
      
      path: "/main",
      element: <>dd</>,
    }, 
    {
      
      path: "/answer/:answerNum",
      element: <AnswerPage/>,
    },
    {
      
      path: "/result",
      element: <ResultPage/>,
    },
    {
      
      path: "/oauth/callback",
      element: <LoadingPage/>,
    },
    {
      
      path: "/pre-noti",
      element: <PreNotiPage/>,
    },
    {
      
      path: "/google-info",
      element: <GoogleInfoPage/>,
    },
    {
      
      path: "/kakao-info",
      element: <KakaoInfoPage/>
    },

]
}

],

);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RecoilRoot >
    <RouterProvider router={router} />
  </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
