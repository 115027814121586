import { AppBar } from '@mui/material';
import React from 'react';
import { appBarColor } from '../const/const';

function CustomAppbar(props) {
    return (
       <AppBar
       sx={{
        height:"36px",
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        paddingLeft:"56px",
        backgroundColor:appBarColor,
        // borderBottom:"1px solid grey",
        fontWeight:"bold",
        fontSize:"20px"
       }}
variant='elevation'
>노네임</AppBar>
    );
}

export default CustomAppbar;