import { CircularProgress, Dialog, DialogContent, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AnswerBtn from '../components/AnswerBtn';
import { useLocation, useNavigate } from 'react-router-dom';
import { bgColor } from '../const/const';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import { useRecoilState } from 'recoil';
import { answerResState, authTest } from '../atom/atom';
import Like from "../images/like-mb.png"
import WideLike from "../images/like-wide.png"
import Good from "../images/good-mb.png"
import WideGood from "../images/good-wide.png"
import ForAnother from "../images/for-u-mb.png"
import WideForAnother from "../images/for-u-wide.png"
import Flaw from "../images/flaw-mb.png"
import WideFlaw from "../images/flaw-wide.png"
import Digital from "../images/digital-mb.png"
import WideDigital from "../images/digital-wide.png"
import Applaud from "../images/applaud-mb.png"
import WideApplaud from "../images/applaud-wide.png"
import EmptyBg from "../images/empty-wide-bg.png"
import StarIcon from "../images/star_icon.png"
import axios from 'axios';
import { FcAbout } from "react-icons/fc";
import { Mobile, PC } from '../components/ReactiveLayout';
import KakaoChannelAddBtn from '../components/KakaoChannelAddBtn';
import { db } from '../service/firebase';

function AnswerPage(props) {
    const location = useLocation()
    const navigate = useNavigate()
    const [test, setTest] = useRecoilState(authTest)
    const newObj =JSON.parse(localStorage.getItem("authInfo"))
    const [innerHeight, setInnerHeight] = useState(window.innerHeight);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const answerNum =  parseInt(sessionStorage.getItem("answerNum")??0)//null//location.state.answerNum
    const [userAnswers, setUserAnswers] = useState(Array(6).fill(''));
    const [loading, setLoading] = useState(false)




    const handleAnswerChange = (event, index) => {
        const newAnswers = [...userAnswers];
        newAnswers[index] = event.target.value;
        setUserAnswers(newAnswers);
    };
    const apiKey = 'sk-proj-OE9ZGxO56gJrvfeIxfKOT3BlbkFJGZg18PWvPo97FAomXqHE';
    const endpoint = "https://api.openai.com/v1/chat/completions";


// 결과를 저장할 문서 참조


// // 결과를 저장하는 함수
// const saveResultToFirestore = async (resultString,docId) => {
//     try {
//         // 문서가 존재하는지 확인
//         const resultRef = doc(db, "result", docId);
//         const docSnapshot = await resultRef.get();
        
//         // 문서가 존재하지 않으면 생성하여 결과 저장
//         if (!docSnapshot.exists()) {
//             await setDoc(resultRef, { result: resultString });
//             console.log("새로운 문서를 생성하고 결과를 저장했습니다.");
//         } else {
//             console.log("이미 존재하는 문서에 결과를 저장합니다.");
//         }
//     } catch (error) {
//         console.error("결과를 저장하는 중에 오류가 발생했습니다:", error);
//     }
// };

    const askGPT = async () => {
if(answerNum===5){
    sessionStorage.setItem("digital-skill",userAnswers[answerNum])
}
setUserAnswers([
    sessionStorage.getItem("like"),
    sessionStorage.getItem("flaw"),
    sessionStorage.getItem("for-u"),
    sessionStorage.getItem("good"),
    sessionStorage.getItem("applaud"),
    userAnswers[5]
])
        const prompt = `
            당신은 지금부터 디지털 노마드 직업군을 추천해주는 직업상담사 라고 가정하고 답변해주세요.
            제가 미리 설명하는 디지털 노마드 직업군 중에 제가 나열하는 저의 특징들을 반영해서 잘 어울리는 디지털 노마드 직업을 추천해주시면 감사드리겠습니다.


            디지털 노마드 직업군으로는 

            콘텐츠 크리에이터: 블로그, 유튜브, 팟캐스트 등 다양한 플랫폼에서 자신만의 콘텐츠를 제작하고 공유합니다.
            소셜 미디어 매니저: 기업이나 브랜드의 소셜 미디어 계정을 관리하며, 마케팅 전략을 수립합니다.
            온라인 교육 코치: 다양한 분야에서 온라인으로 지식을 전달하고, 학습자를 지도합니다.
            프리랜서 작가 / 칼럼니스트: 다양한 주제에 대해 글을 쓰고, 온라인 매체나 출판사를 통해 작품을 발표합니다.
            웹 개발자 / 프론트엔드 개발자: 웹사이트나 웹 애플리케이션을 개발하고 유지보수합니다.
            UI/UX 디자이너: 사용자 경험(UX)과 사용자 인터페이스(UI) 디자인을 통해 제품이나 서비스의 사용성을 높입니다.
            SEO 전문가: 웹사이트의 검색 엔진 최적화를 담당하여, 검색 결과에서의 노출을 높입니다.
            디지털 마케터: 온라인을 통한 광고 및 마케팅 전략을 수립하고 실행합니다.
            데이터 분석가: 데이터를 수집, 분석하여 비즈니스 인사이트를 제공합니다.
            블록체인 개발자: 블록체인 기술을 활용한 애플리케이션 개발에 참여합니다.
            사이버 보안 전문가: 정보 보안을 위한 전략을 수립하고, 보안 위협으로부터 시스템을 보호합니다.
            모바일 앱 개발자: 스마트폰을 위한 애플리케이션을 개발합니다.
            클라우드 컴퓨팅 전문가: 클라우드 기반의 서비스와 인프라를 관리하고 최적화합니다.
            인공지능(AI) 개발자: 인공지능 알고리즘과 시스템을 개발합니다.
            게임 개발자: 컴퓨터나 모바일 게임을 개발합니다.
            온라인 커뮤니티 매니저: 온라인 커뮤니티를 운영하고, 사용자 간의 소통을 촉진합니다.
            프로젝트 매니저: 다양한 프로젝트를 관리하며, 프로젝트의 목표 달성을 위해 팀을 이끕니다.
            온라인 비즈니스 컨설턴트: 온라인 비즈니스 전략을 수립하고, 실행을 돕습니다.
            프리랜서 번역가 / 통역가: 다양한 언어로 문서를 번역하거나, 통역 서비스를 제공합니다.
            사진작가 / 비디오그래퍼: 사진이나 비디오 촬영을 통해 콘텐츠를 제작합니다.
            온라인 광고 전문가: 인터넷 광고 캠페인을 기획하고 실행합니다.
            가상현실(VR) 개발자: 가상현실 콘텐츠와 애플리케이션을 개발합니다.
            클라우드 서비스 매니저: 클라우드 기반 서비스를 관리하고 최적화합니다.
            데이터 사이언티스트: 데이터 분석을 통해 비즈니스 인사이트를 도출합니다.
            인플루언서 / 블로거: 개인 브랜드를 구축하고 소셜 미디어를 통해 영향력을 행사합니다.
            온라인 튜터 / 언어 교사: 인터넷을 통해 학생들에게 지식을 전달합니다.
            전자책 작가: 디지털 형식의 책을 집필하고 출판합니다.
            프리랜서 연구원: 다양한 연구 프로젝트에 참여합니다.
            온라인 이벤트 플래너: 웹 세미나, 온라인 회의 등을 기획하고 실행합니다.
            소프트웨어 테스터: 소프트웨어의 버그를 찾고 품질을 평가합니다.
            디지털 미디어 전략가: 디지털 미디어 콘텐츠의 기획과 실행을 담당합니다.
            온라인 마켓플레이스 운영자: 온라인 상에서 상품이나 서비스를 판매합니다.
            프리랜서 컨설턴트: 전문 지식을 바탕으로 조언을 제공합니다.
            콘텐츠 마케팅 전문가: 유용한 콘텐츠를 제작하여 마케팅 목표를 달성합니다.
            앱 스토어 최적화(ASO) 전문가: 앱 스토어 내에서 앱의 가시성을 높입니다.
            드론 조종사 / 촬영가: 드론을 이용한 촬영 서비스를 제공합니다.
            온라인 PR 전문가: 인터넷을 통해 기업이나 개인의 이미지 관리를 담당합니다.
            웹 애널리틱스 전문가: 웹사이트의 데이터를 분석하여 인사이트를 제공합니다.
            디지털 자산 관리자: 디지털 자산(사진, 비디오 등)의 관리와 최적화를 담당합니다.
            프리랜서 기술 작가: 기술 문서, 사용자 매뉴얼 등을 작성합니다.

            등이 있으며 이제부터 저의 특성을 말씀드리겠습니다.
            

            제가 좋아하는 것은 ${ sessionStorage.getItem("like")}
            제가 잘하는 것은 ${sessionStorage.getItem("good")}
            제 단점은 ${sessionStorage.getItem("flaw")}
            제 장점은 ${sessionStorage.getItem("applaud")}
            제가 제공할 수 있는 것은 ${sessionStorage.getItem("for-u")}
            제가 가진 디지털 역량은 ${userAnswers[5]}

            제가 가진 역량으로 적합한 디지털 노마드 직업군을 추천해주시면 감사드리겠습니다.

            보기 좋게 줄바꿈과 문단 나누기를 사용해서 답변을 주시면 감사드리겠습니다.

        `;

        const config = {
            headers: {
                'Authorization': `Bearer ${apiKey}`,
                'Content-Type': 'application/json',
            },
            data: {
                model: "gpt-4",
                messages: [{
                    role: "user",
                    content: prompt
                }]
            }
        };

        try {
            setLoading(true)
            const response = await axios.post(endpoint, config.data, { headers: config.headers });
            // alert("Response from OpenAI:", response.data.choices[0].message.content)
            // 응답 결과를 처리하는 로직 추가 
            const newAnswerRes = response.data.choices[0].message.content
            // setAnswerRes(newAnserRes)
            setLoading(false)
            localStorage.setItem("result",newAnswerRes)
            //디비저장
        //    await saveResultToFirestore(newAnswerRes, newObj.email)
            navigate("/result", 
            {
                state: {
                    result: newAnswerRes
                }
            })
        } catch (error) {
            // alert("Error while calling OpenAI API:", error)
            console.error("Error while calling OpenAI API:", error);
        }
    };

    const onAnswer = () => {
        //답변 칸 비어있으면 넘어가지 않게
        const skipCondition = userAnswers[answerNum].trim() != ""
        if (answerNum===0) {
            sessionStorage.setItem("like",userAnswers[answerNum])
        } 
        else if(answerNum===1){
            sessionStorage.setItem("flaw",userAnswers[answerNum])
        }
        else if(answerNum===2){
            sessionStorage.setItem("for-u",userAnswers[answerNum])
        }
        else if(answerNum===3){
            sessionStorage.setItem("good",userAnswers[answerNum])
        }
        else if(answerNum===4){
            sessionStorage.setItem("applaud",userAnswers[answerNum])
        }
        else {
            
        }
        if (!skipCondition) {

            window.confirm("답변을 작성 해 주세요.!")
            return;
        }
        else {
            if (answerNum === 5) {
                const res = window.confirm("답변을 제출하시겠습니까?");
                if (res) {
                    askGPT().finally(res => console.log(res)).catch(err => window.confirm("에러가 발생했습니다.", err));
                
                
                } else {
                    return;
                }


            }
            if (answerNum<5) {
                 const nextAnswerNum = answerNum + 1
            const originUserInfo = location.state.userInfo
            sessionStorage.setItem("answerNum",nextAnswerNum)
            navigate(`/answer/:${nextAnswerNum}`, { state: { answerNum: nextAnswerNum, userInfo: originUserInfo, } })
       
            }
            }

    }

    const handleResize = () => {
        setInnerHeight(window.innerHeight);
        setInnerWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            // cleanup
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const getImg = (pageNum, type) => {
        if (type === "sm") {
            if (pageNum === 0) {
                return Like
            } else if (pageNum === 1) {
                return Flaw
            } else if (pageNum === 2) {
                return ForAnother
            } else if (pageNum === 3) {
                return Good
            } else if (pageNum === 4) {
                return Applaud
            } else if (pageNum === 5) {
                return Digital
            } else {
                return ""
            }
        } else {
            if (pageNum === 0) {
                return WideLike
            } else if (pageNum === 1) {
                return WideFlaw
            } else if (pageNum === 2) {
                return WideForAnother
            } else if (pageNum === 3) {
                return WideGood
            } else if (pageNum === 4) {
                return WideApplaud
            } else if (pageNum === 5) {
                return WideDigital
            } else {
                return ""
            }
        }

    }

    const getAnswer = (pageNum) => {
        if (pageNum === 0) {
            return <div
                style={{
                    minWidth: "300px",
                    // fontSize:"14px",
                    // fontWeight:700,
                    display: "flex",
                    flexDirection: "column",
                    gap: "3px",
                    textAlign: "start"
                }}
            >
                <div
                    style={{

                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                        alignItems: "center"
                    }}
                ><FcAbout size={30} />
                    <span
                        style={{
                            width: "100%",
                            fontSize: "20px",
                            textAlign: "center",
                            // fontWeight:700
                        }}
                    >내가 좋아하는 것은?</span>
                </div><span
                    style={{
                        fontWeight: "normal",
                        textAlign: "center",
                        fontSize: "16px"
                    }}
                >(예:축구를 좋아해요 / 요리하는 것을 좋아해요 / 드라마 보는것을 좋아해요)</span></div>
        } else if (pageNum === 1) {
            return <div
                style={{
                    minWidth: "300px",
                    // fontSize:"14px",
                    // fontWeight:700,
                    display: "flex",
                    flexDirection: "column",
                    gap: "3px",
                    textAlign: "start"
                }}
            >
                <div
                    style={{

                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                        alignItems: "center"
                    }}
                ><FcAbout size={30} />
                    <span
                        style={{
                            width: "100%",
                            fontSize: "20px",
                            textAlign: "center",
                            // fontWeight:700
                        }}
                    >나의 단점은?</span>
                </div><span style={{
                    fontWeight: "normal",
                    textAlign: "center",
                    fontSize: "16px"
                }}>(예: 게으르고 끈기가 없어요 / 성질이 더러워요)</span></div>
        } else if (pageNum === 2) {
            return <div
                style={{
                    minWidth: "300px",
                    // fontSize:"14px",
                    // fontWeight:700,
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                    textAlign: "start"
                }}
            >
                <div
                    style={{

                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                        alignItems: "center"
                    }}
                ><FcAbout size={30} />
                    <span
                        style={{
                            width: "100%",
                            fontSize: "20px",
                            textAlign: "center",
                            // fontWeight:700
                        }}
                    >내가 남들에게 줄 수 있는 것은?</span>
                </div><span style={{
                    fontWeight: "normal",
                    textAlign: "center",
                    fontSize: "16px"
                }}>(예: 돈 빼고 다 줄수 있어요 배경지식 , 사랑 , 믿음 배려..)</span></div>
        } else if (pageNum === 3) {
            return <div
                style={{
                    minWidth: "300px",
                    // fontSize:"14px",
                    // fontWeight:700,
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                    textAlign: "start"
                }}
            >
                <div
                    style={{

                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                        alignItems: "center"
                    }}
                ><FcAbout size={30} />
                    <span
                        style={{
                            width: "100%",
                            fontSize: "20px",
                            textAlign: "center",
                            // fontWeight:700
                        }}
                    >내가 잘하는것은?</span>
                </div><span style={{
                    fontWeight: "normal",
                    textAlign: "center",
                    fontSize: "16px"
                }}>(예: 만들기를 잘 해요 / 운동을 잘 해요 / 여행 계획을 잘 짜요)</span></div>
        } else if (pageNum === 4) {
            return <div
                style={{
                    minWidth: "300px",
                    // fontSize:"14px",
                    // fontWeight:700,
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                    textAlign: "start"
                }}
            >
                <div
                    style={{

                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                        alignItems: "center"
                    }}
                ><FcAbout size={30} />
                    <span
                        style={{
                            width: "100%",
                            fontSize: "20px",
                            textAlign: "center",
                            // fontWeight:700
                        }}
                    >남에게 자주듣는 칭찬은?</span>
                </div><span style={{
                    fontWeight: "normal",
                    textAlign: "center",
                    fontSize: "16px"
                }}>(예: 외모 칭찬을 많이 듣는 편이고, 성격이 좋고 배려심이 많다는 칭찬을 많이 들어요)</span></div>
        } else if (pageNum === 5) {
            return <div
                style={{
                    minWidth: "300px",
                    // fontSize:"14px",
                    // fontWeight:700,
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                    textAlign: "start"
                }}
            >
                <div
                    style={{

                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                        alignItems: "center"
                    }}
                ><FcAbout size={30} />
                    <span
                        style={{
                            width: "100%",
                            fontSize: "20px",
                            textAlign: "center",
                            // fontWeight:700
                        }}
                    >내가 가지고있는 디지털 역량은?</span>
                </div><span style={{
                    fontWeight: "normal",
                    textAlign: "center",
                    fontSize: "16px"
                }}>(예: 마케팅을 오래 해 왔어요 영상 편집도 능숙하게 잘 하는 편이고 온라인 가격 비교와 온라인 판매에 능숙한 편이에요)</span></div>
        } else {
            return ""
        }
    }
// const goResult=()=>{
//     localStorage.setItem("result",newAnswerRes)
//     navigate("/result", {
//         state: {
//             result: newAnswerRes
//         }
//     })
// }
    return (

        <div>
            <Mobile>
                {loading && <div
                    // className='dialog-bg'
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 998,
                        backgroundColor: "rgba(0,0,0,0.5)"
                    }}
                ><div

                    style={{
                        borderRadius: "20px",
                        position: "absolute",
                        bottom: "calc(90px + 10px)",
                        width: "300px",
                        height: `calc(4.7vh * 11)`,
                        left: `calc(50% - 150px)`
                    }}
                ><DialogContent

                    sx={{
                        borderRadius: "20px",
                        position: "absolute",
                        bottom: 0,
                        overflow: "hidden",
                        width: "300px",
                        height: `calc(4.7vh * 11)`,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 999,

                    }}
                >
                            <img src={EmptyBg}
                                draggable={false}
                                style={{
                                    zIndex: 900,
                                    width: "100%",
                                    height: "100%",
                                    position: "absolute",
                                    left: 0,
                                    bottom: 0,
                                    overflow: "hidden"
                                }} />
                            <CircularProgress
                                sx={{
                                    color: "white",
                                    zIndex: 999
                                }}
                                size={100}
                            />
                            <div
                                style={{
                                    height: "16px"
                                }}
                            />
                            <Typography
                                sx={{
                                   
                                    zIndex: 999,
                                    color: "white",
                                    fontWeight: 700
                                }}
                            >
                                <p
                                style={{
                                    
                                }}
                                >

                                    <span
                                    style={{
                                         fontSize:"17.6px",
                                    }}
                                    >조금만 기다려주세요</span>  <img src={StarIcon}
                                   
                                    /></p>
                                <p
                                style={{
                                     fontSize:"17.6px",
                                }}
                                >당신의 직업군을 분석 중입니다.</p>
                            </Typography></DialogContent></div></div>}
                <Box sx={{}}>
                    <Fade in={true} timeout={1300} style={{}}>
                        <Paper
                            sx={{
                                margin: 0,
                                padding: 0,
                                backgroundColor: "transparent"
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",

                                    gap: "40px",
                                }}
                            >
                                <img
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: `calc(${innerWidth} / 2)`,
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover" // 이미지 비율 유지를 위해 추가
                                    }}
                                    src={getImg(answerNum, "sm")}
                                />

                                <div
                                    className='glass'
                                    style={{
                                        marginLeft: "8px",
                                        marginRight: "8px",
                                        marginTop: "8px",
                                        fontWeight: 700,
                                        fontSize: 14,
                                        color: "white",
                                        padding: "8px",
                                    }}
                                    variant='h5' color={"white"}
                                >
                                    {getAnswer(answerNum)}
                                </div>

                                {/* 선택지 - 데이터 내에 리스트로 존재하면 될 듯 */}
                                <ul
                                    style={{
                                        position: "absolute",
                                        bottom: "106px",
                                        zIndex: 900,
                                        listStyle: "none",
                                        margin: 0,
                                        padding: 0,
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px",
                                        // width:innerWidth <= 800 ? "360px":"450px",
                                        height: `100%`,
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        // paddingBottom:"106px"
                                    }}
                                >
                                    <li>
                                        <div style={{ height: "8px" }} />
                                    </li>
                                    <li>
                                        <textarea
                                            placeholder='생각나는 대로 상세히 적어주세요.!'
                                            color='white'
                                            style={{
                                                padding: "8px",
                                                fontSize: "16px",
                                                height: `calc((${innerHeight}px - 90px - 200px) * 0.63)`,//`calc(4.7vh * 8)`,
                                                width: "260px",
                                                resize: "none",
                                                backgroundColor: "transparent",
                                                color: "white",
                                                border: "3px solid white",
                                                outline: "none"
                                            }}
                                            value={userAnswers[answerNum]}
                                            onChange={(event) => handleAnswerChange(event, answerNum)}
                                        />
                                    </li>
                                    <li>
                                       {answerNum===5?<KakaoChannelAddBtn
                                       answer={userAnswers[answerNum]}
                                        goResult={askGPT}
                                        type={"mobile"}
                                        width={"300px"}
                                       />: <AnswerBtn
                                        type={"mobile"}
                                            width={"300px"}
                                            title={"다음 질문으로 넘어가기"}
                                            onAnswer={onAnswer}
                                        />}
                                    </li>
                                </ul>
                            </div>
                        </Paper>
                    </Fade>
                </Box>
            </Mobile>
            <PC>
                {loading && <div
                    // className='dialog-bg'
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: innerWidth,
                        height: innerHeight,
                        zIndex: 998,
                        backgroundColor: "rgba(0,0,0,0.5)"
                    }}
                ><div

                    style={{
                        borderRadius: "20px",
                        position: "absolute",
                        bottom: "calc(90px + 10px)",
                        width: "420px",
                        height: "300px",
                        left: `calc(50% - 210px)`
                    }}
                ><DialogContent

                    sx={{
                        borderRadius: "20px",
                        position: "absolute",
                        bottom: 0,
                        overflow: "hidden",
                        width: "420px",
                        height: "300px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 999,

                    }}
                >
                            <img src={EmptyBg}
                                draggable={false}
                                style={{
                                    zIndex: 900,
                                    width: "100%",
                                    height: "100%",
                                    position: "absolute",
                                    left: 0,
                                    bottom: 0,
                                    // overflow:"hidden"
                                }} />
                            <CircularProgress
                                sx={{
                                    color: "white",
                                    zIndex: 999
                                }}
                                size={100}
                            />
                            <div
                                style={{
                                    height: "16px"
                                }}
                            />
                            <Typography
                                sx={{
                                    zIndex: 999,
                                    color: "white",
                                    fontWeight: 700
                                }}
                            >
                                <p>

                                    <span>조금만 기다려주세요...</span>  <img src={StarIcon} /></p>
                                <p>당신의 직업군을 분석 중입니다. <img src={StarIcon} /></p>
                            </Typography></DialogContent></div></div>}
                <Box sx={{}}>
                    <Fade in={true} timeout={1300} style={{}}>
                        <Paper
                            sx={{
                                margin: 0,
                                padding: 0,
                                backgroundColor: "transparent"
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",

                                    gap: "40px",
                                }}
                            >
                                <img
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: `calc(${innerWidth} / 2)`,
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover" // 이미지 비율 유지를 위해 추가
                                    }}
                                    src={getImg(answerNum, "wide")}
                                />

                                <div
                                    className='glass'
                                    style={{
                                        marginLeft: "8px",
                                        marginRight: "8px",
                                        marginTop: "8px",
                                        fontWeight: 700,
                                        fontSize: 18,
                                        color: "white",
                                        padding: "8px",
                                    }}
                                    variant='h5' color={"white"}
                                >
                                    {getAnswer(answerNum)}
                                </div>

                                {/* 선택지 - 데이터 내에 리스트로 존재하면 될 듯 */}
                                <ul
                                    style={{
                                        position: "absolute",
                                        bottom: "106px",
                                        zIndex: 900,
                                        listStyle: "none",
                                        margin: 0,
                                        padding: 0,
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px",
                                        // width:innerWidth <= 800 ? "360px":"450px",
                                        height: `100%`,
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        // paddingBottom:"106px"
                                    }}
                                >
                                    <li>
                                        <div style={{ height: "8px" }} />
                                    </li>
                                    <li>
                                        <textarea
                                            placeholder='생각나는 대로 상세히 적어주세요.!'
                                            color='white'
                                            style={{
                                                padding: "8px",
                                                fontSize: "16px",
                                                height: `calc(4.7vh * 2)`,
                                                width: "400px",
                                                resize: "none",
                                                backgroundColor: "transparent",
                                                color: "white",
                                                border: "3px solid white",
                                                outline: "none"
                                            }}
                                            value={userAnswers[answerNum]}
                                            onChange={(event) => handleAnswerChange(event, answerNum)}
                                        />
                                    </li>
                                    <li>
                                    {answerNum===5?<KakaoChannelAddBtn
                                    answer={userAnswers[answerNum]}
                                    goResult={askGPT}
                                    width={"420px"}
                                    />: <AnswerBtn
                                            width={"420px"}
                                            title={"다음 질문으로 넘어가기"}
                                            onAnswer={onAnswer}
                                        />}
                                    </li>
                                </ul>
                            </div>
                        </Paper>
                    </Fade>
                </Box>

            </PC>
        </div>
        // <div
        //     style={{
        //         display: 'flex',
        //         width: "100%",
        //         height: '100%',
        //         alignItems: "flex-start",
        //         justifyContent: "center",
        //         backgroundColor: bgColor,
        //         // paddingTop:"16px",
        //         // paddingBottom:"16px",
        //     }}
        // >

        //   {loading&&  <div
        //     // className='dialog-bg'
        //     style={{
        //         position:"absolute",
        //         top:0,
        //         left:0,
        //         width:innerWidth,
        //         height:innerHeight,
        //         zIndex:998,
        //         backgroundColor:"rgba(0,0,0,0.5)"
        //     }}
        //     ><div

        //     style={{
        //         borderRadius:"20px",
        //         position:"absolute",
        //         bottom:"calc(90px + 10px)",
        //         width: innerWidth <= 800 ?"320px":"420px",
        //         height: innerWidth <= 800?`calc(4.7vh * 11)`:"300px",
        //         left:`calc(50% - ${innerWidth<=800?"160px":"210px"})`
        //     }}
        //     ><DialogContent

        //     sx={{
        //         borderRadius:"20px",
        //         position:"absolute",
        //         bottom:0,
        //         overflow:"hidden",
        //         width: innerWidth <= 800 ?"320px":"420px",
        //         height: innerWidth <= 800?`calc(4.7vh * 11)`:"300px",
        //         display:"flex",
        //         flexDirection:"column",
        //         alignItems:"center",
        //         justifyContent:"center",
        //         zIndex:999,

        //     }}
        //     >
        //         <img src={EmptyBg} 
        //         draggable={false}
        //         style={{
        //             zIndex:900,
        //             width:"100%",
        //             position:"absolute",
        //             left:0,
        //             bottom:0,
        //             overflow:"hidden"
        //         }}/>
        //         <CircularProgress
        //         sx={{
        //             color:"white",
        //             zIndex:999
        //         }}
        //         size={100}
        //     />
        //     <div
        //     style={{
        //         height:"16px"
        //     }}
        //     />
        //     <Typography
        //     sx={{
        //         zIndex:999,
        //         color:"white",
        //         fontWeight:700
        //     }}
        //     >
        //         <p>

        //           <span>조금만 기다려주세요...</span>  <img src={StarIcon}/></p>
        //         <p>당신의 직업군을 분석 중입니다. <img src={StarIcon}/></p>
        //          </Typography></DialogContent></div></div>}


        // <Box sx={{}}>
        //     <Fade in={true} timeout={1300} style={{}}>
        //         <Paper
        //             sx={{
        //                 margin: 0,
        //                 padding: 0,
        //                 backgroundColor: "transparent"
        //             }}
        //         >
        //             <div
        //                 style={{
        //                     display: "flex",
        //                     flexDirection: "column",
        //                     alignItems: "center",
        //                  justifyContent:"center",
        //                     width: innerWidth,
        //                     height: innerHeight,
        //                     backgroundColor:"red",
        //                     gap: "40px",
        //                 }}
        //             >
        //                 <img
        //                     style={{  
        //                         position: "absolute"
        //                     }}
        //                    src={getImg(answerNum,innerWidth <= 800 ? "sm":"wide")}
        //                 />
        //                 {/* 질문 순서 */}
        //                 {/* <Typography
        //                     sx={{
        //                         zIndex: 900
        //                     }}
        //                     variant='h5' color={"white"}
        //                 >
        //                     {answerNum + 1} / 6
        //                 </Typography> */}
        //                 {/* 질문지 */}
        //                 <div
        //                 className='glass'
        //                    style={{
        //                     marginLeft:"8px",
        //                     marginRight:"8px",
        //                     marginTop:"8px",
        //                     fontWeight:700,
        //                     color:"white",
        //                     padding:"8px",
        //                    }}
        //                     variant='h5' color={"white"}
        //                 >
        //                     {getAnswer(answerNum)}
        //                 </div>
        //                 {/* 질문에 대한 이미지 넣는 곳 */}
        //                 <div
        //                     style={{
        //                         width: "300px",
        //                         height: "200px",
        //                         backgroundColor: "grey"
        //                     }}
        //                 />
        //                 {/* <div
        //                     style={{
        //                         height: "1px",
        //                         width: "300px",
        //                         backgroundColor: "white",
        //                         opacity: "0.5"
        //                     }}
        //                 /> */}
        //                 {/* 선택지 - 데이터 내에 리스트로 존재하면 될 듯 */}
        //                 <ul
        //                     style={{
        //                         zIndex: 900,
        //                         listStyle: "none",
        //                         margin: 0,
        //                         padding: 0,
        //                         display: "flex",
        //                         flexDirection: "column",
        //                         gap: "10px",
        //                         // width:innerWidth <= 800 ? "360px":"450px",
        //                         height:`100%`,
        //                         alignItems:"center",
        //                         justifyContent:"flex-end",
        //                         paddingBottom:"106px"
        //                     }}
        //                 >
        //                     <li>
        //                         <div style={{ height:"8px" }} />
        //                     </li>
        //                     <li>
        //                         <textarea
        //                             placeholder='생각나는 대로 상세히 적어주세요.!'
        //                             color='white'
        //                             style={{
        //                                 padding: "8px",
        //                                 fontSize: "16px",
        //                                 height: innerWidth <= 800?`calc(4.7vh * 9)`:`calc(4.7vh * 2)`,
        //                                 width: innerWidth <= 800 ?"300px":"400px",
        //                                 resize: "none",
        //                                 backgroundColor: "transparent",
        //                                 color: "white",
        //                                 border: "3px solid white",
        //                                 outline: "none"
        //                             }}
        //                             value={userAnswers[answerNum]}
        //                             onChange={(event) => handleAnswerChange(event, answerNum)}
        //                         />
        //                     </li>
        //                     <li>
        //                         <AnswerBtn
        //                             width={innerWidth <= 800 ?"320px":"420px"}
        //                             title={"다음 질문으로 넘어가기"}
        //                             onAnswer={onAnswer}
        //                         />
        //                     </li>
        //                 </ul>
        //             </div>
        //         </Paper>
        //     </Fade>
        // </Box>
        // </div>
    );
}

export default AnswerPage;