import React, { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { bgColor } from '../const/const';
import {useNavigate,useLocation} from "react-router-dom"
import instance from '../service/api';
function LoadingPage(props) {
    const navigate = useNavigate();
const location=useLocation()
    useEffect(() => {
        // console.log("카카오 로그인 auth >>>",JSON.stringify(localStorage.getItem("auth")))
        // window.Kakao.API.request({
        //     url: '/v2/user/me',
        //   })
        //     .then(function(response) {
        //       console.log('카카오 사용자 정보 res>>>',response);
        //     })
        //     .catch(function(error) {
        //       console.log('카카오 사용자 정보 error>>>',error);
        //     });
        const originAuth=localStorage.getItem("authInfo")//location.state.authInfo;
        const timer = setTimeout(() => {
            // navigate('/answer/:0',{
            //     state: {
            //         answerNum: 0,
            //         authInfo:originAuth
            //     }
            // }); 
            navigate('/pre-noti',{
                state: {
                    answerNum: 0,
                    authInfo:originAuth
                }
            });
        }, 1000); // 1초 후에 이동

        return () => clearTimeout(timer); // 컴포넌트가 언마운트될 때 타이머 정리
    }, [navigate]);
    return (
        <div
        style={{
            display:'flex',
            width:"100%",
            height: '100vh',
            alignItems:"center",
            justifyContent:"center",
            backgroundColor:bgColor,

         
        }}
        >
            <CircularProgress 
            size={100}
            />
        </div>
    );
}

export default LoadingPage;