import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db, provider } from '../service/firebase';
import { useRecoilState } from 'recoil';
import { nowUserState } from '../atom/atom';
import GoogleIcon from "../images/google-icon.png"
import { doc, setDoc } from 'firebase/firestore';

function GoogleLoginBtn({type}) {
    const navigate = useNavigate()
    const [userInfo, setUserInfo] = useRecoilState(nowUserState)
    const [hover, setHover] = useState(false)
    const redirectUri = `${window.location.origin}/oauth/callback`;

    const onMouseEnter = () => {
        setHover(true)
    }
    const onMouseLeave = () => {
        setHover(false)
    }

    const handleGoogleLogin = () => {
        
        signInWithPopup(auth, provider)
            .then(async result => {
                // setUserInfo(result.user);
                const displayName=result.user.displayName
                const phoneNumber=result.user.phoneNumber
                const birthday=""
                const email = result.user.email
                const newObj={
                    type:"google",
                    //data.profile.properties.name
                    name:displayName,
                    //data.profile.properties.birthyear
                    birth:birthday,
                    //data.profile.properties.phone_number
                    phone_number:phoneNumber,
                    //data.profile.properties.account_email
                    email:email
                }
                localStorage.setItem("authInfo",JSON.stringify(newObj))
                   navigate("/google-info",{
                state:{newObj:newObj}
            })
                
            })
            .catch(error => {
                console.log(error);
            });
    };

    return (
      type==="small"?<button
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
          cursor: "pointer",
          backgroundColor: hover ? "rgba(255,255,255, 0.3)" : "rgba(255,255,255, 0)",
          color: "white",
          border: "3px solid rgba(255,255,255, 0.6)",
          width: "60px",
          height:"60px",
          // paddingLeft: "50px",
          // paddingRight: "50px",
          fontSize: "18px",
          borderRadius: "50px",
          fontWeight: "bold",
          alignItems:"center",justifyContent:"center"
      }}
      onClick={handleGoogleLogin}
  >
     
          <div
           className='glass'
              style={{
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "50%",
                  justifyContent: "center",
                  backgroundColor: "rgba(255,255,255, 0.3)"
              }}
          ><img width="20px" height="20px" src={GoogleIcon} /></div>
          
     

  </button>: <button
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                style={{
                    cursor: "pointer",
                    backgroundColor: hover ? "rgba(255,255,255, 0.3)" : "rgba(255,255,255, 0)",
                    color: "white",
                    border: "3px solid rgba(255,255,255, 0.6)",
                    width: "200px",
                    height:"50px",
                    // paddingLeft: "50px",
                    // paddingRight: "50px",
                    fontSize: "18px",
                    borderRadius: "50px",
                    fontWeight: "bold",
                }}
                onClick={handleGoogleLogin}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "8px",
                        paddingRight: "8px"
                    }}
                >
                    <div
                        style={{
                            width: "32px",
                            height: "32px",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "50%",
                            justifyContent: "center",
                            backgroundColor: "rgba(255,255,255, 0.3)"
                        }}
                    ><img width="20px" height="20px" src={GoogleIcon} /></div>
                 {  <span
                    style={{
                        fontSize:"12px",
                        opacity:0.8
                        // backgroundColor:"red"

                    }}
                    >구글 로그인</span>}</div>

            </button>
    );
}

export default GoogleLoginBtn;