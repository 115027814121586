import { useEffect, useState } from 'react';
import StarImg from '../images/star_icon.png';

export default function KakaoChannelAddBtn({ width, type, goResult,answer }) {
  const [hover, setHover] = useState(false);

  const onEnter = () => setHover(true);
  const onLeave = () => setHover(false);

  // Kakao SDK 초기화 및 채널 추가 함수
  const initializeKakao = () => {
    if (window.Kakao) {
      const kakao = window.Kakao;
      if (!kakao.isInitialized()) {
        kakao.init("c1b71d1830f20299a67f94a767f5c472");
      }
      if (kakao.Channel && typeof kakao.Channel.followChannel === 'function') {
      
      } else {
        //init안돼서 강제 reload
        window.location.reload();
      }
    } else {
      console.error('Kakao SDK is not loaded');
    }
  };

  const addKakaoChannel = () => {
    try {if(answer.trim()===""){

      window.confirm("빈칸을 채워주세요")
    }else{
        // const kakao = window.Kakao;
      const kakao = window.Kakao;
      if (!kakao.isInitialized()) {
        // kakao.init("c1b71d1830f20299a67f94a767f5c472");
      }
      if (kakao.Channel && typeof kakao.Channel.followChannel === 'function') {
        kakao.Channel.followChannel({
          channelPublicId: '_xexnqxeG'
        })
          .then(function (response) {
            if (response.status === "success" || response.status === "fail") {
              goResult();
            } else {
              window.confirm("채널 추가 실패");
            }
          })
          .catch(function (error) {
            window.confirm("채널 추가 실패");
            console.error('간편추가 실패', error);
          });
      } else {
        //init안돼서 강제 reload
        // window.location.reload();
      }
    }
    
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    // Kakao SDK 초기화
    initializeKakao();
  }, []);

  return (
    <>
      <div
        id='kakao-talk-channel-add-button'
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
        style={{
          cursor: "pointer",
          backgroundColor: hover ? "rgba(255,255,255, 0.3)" : "rgba(255,255,255, 0)",
          color: "white",
          border: "3px solid white",
          width: width,
          height: "48px",
          display: "flex",
          gap: "16px",
          justifyContent: "center",
          alignItems: "center",
          fontSize: type === "mobile" ? "20px" : "18px",
          borderRadius: "50px",
          fontWeight: "bold",
        }}
        onClick={addKakaoChannel}
      >
        <img src={StarImg} />
        <span>카톡 추가하고 결과 보기</span>
      </div>
    </>
  );
}
