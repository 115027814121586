import { Card, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LoginBtn from '../components/LoginBtn';
// import MainImg from "../images/디지털노마드_대지 1.svg"
import { bgColor } from '../const/const';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import NaverLogin from '../components/NaverLogin';
import MobileBg from "../images/main-mb.png"
import WebBg from "../images/main-wide.png"
import LoginTxtLg from "../images/login-txt-lg.png"
import LoginTxtSm from "../images/login-txt-sm.png"
import GoogleLoginBtn from '../components/GoogleLoginBtn';
import KakaoLoginBtn from '../components/KakaoLoginBtn';
import NaverLoginBtn from '../components/NaverLoginBtn';
import { useLocation, useNavigate } from 'react-router-dom';
import TempLoginDialog from '../components/TempLoginDialog';
import { BiArrowFromBottom, BiArrowFromTop } from 'react-icons/bi';
import { IoIosArrowDown } from 'react-icons/io';
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import CustomFooter from '../components/CustomFooter';
import { Mobile, PC } from '../components/ReactiveLayout';
function LoginPage(props) {
    const loginType = [{ type: "구글", color: "#ffffff" }, { type: "카카오", color: "#FEE500" }, { type: "네이버", color: "#00b869" }]
    const navigate = useNavigate()
    const location = useLocation()
    const [innerHeight, setInnerHeight] = useState(window.innerHeight);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setInnerWidth(window.innerWidth);
        setInnerHeight(window.innerHeight);
    };

    useEffect(() => {


        window.addEventListener("resize", handleResize);

        return () => {
            // cleanup
            window.removeEventListener("resize", handleResize);
        };


    }, []);
    // useEffect(() => {
    //     // 이미 로그인한 사용자가 로그인 페이지에 접근하면 바로 대시보드로 이동
    //     if (sessionStorage.getItem('nowLogin') === 'true') {
    //         const originUserInfo = location.state.userInfo
    //         const test=localStorage.getItem("authInfo")
    //         console.log("auth info in 로그인 페이지",test)
    //         const originUserInfo =localStorage.getItem("authInfo")// location.state.userInfo
    //         navigate(`/answer/:0`, { state: { answerNum: 0, userInfo: originUserInfo, } })

    //     }
    // }, [navigate]);
    return (
        <div>
            <Mobile>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >  <img
        style={{
            position: "absolute",
            top: 0,
            left: `calc(${innerWidth} / 2)`,
            width: "100%",
            height: "100%",
            objectFit: "cover" // 이미지 비율 유지를 위해 추가
        }}

        src={MobileBg} />
          <ul
                            style={{
                                width:"220px",
                                // left: "50%",
                                listStyle: "none",
                                position: "absolute",
                                bottom:"calc(90px + 16px + 25px + 5px + 10px)",
                                display: "flex",
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent: "center",
                                gap:"16px",padding:0,
                                margin:0,

                            }}
                        >
                            <li

                            ><GoogleLoginBtn type={"small" } /></li>
                            <li

                            >
                            <KakaoLoginBtn type={"small"} />
                                {/* <TempLoginDialog type={"small" }/> */}
                                </li>
                            {/* <li

                            ><NaverLoginBtn
                                    type={innerWidth <= 800 ? "small" : "default"}
                                /></li> */}

                        </ul>
                        <div
                        style={{
                            position: "absolute",
                            bottom:`calc(90px + 16px + 10px)`,
                            height:"30px",
                            fontSize:"18px",
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center",
                            color:"white"
                        }}
                        >
                            <img
                             width={"80%"}
                             style={{
                                 aspectRatio:"auto 1/1"
                             }}
                            src={LoginTxtSm}
                            />
                            {/* <span>3초 로그인후 테스트 바로가기!!!</span> */}
                        </div>
                        <CustomFooter  />
                        </div>

            </Mobile>
            <PC>

                <Box>
                    <Fade in={true} timeout={1300}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        ><img
                        style={{
                            position: "absolute",
                            top: 0,
                            left: `calc(${innerWidth} / 2)`,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover" // 이미지 비율 유지를 위해 추가
                        }}
                
                        src={WebBg} />
  <ul
                            style={{
                                // left: `calc(50%)`,
                                // left: innerWidth/2 - 160px,
                                // backgroundColor:"red",
                                listStyle: "none",
                                position: "absolute",
                                bottom:"calc(90px + 16px + 5px)",
                                display: "flex",
                                justifyContent: "center",
                                gap:"16px",
                                margin:0,
                                padding:0,
                                height:innerHeight/7,
                                alignItems:"center"
                            }}
                        >
                            <li

                            ><GoogleLoginBtn type={"default"} /></li>
                            <li

                            >
                                <KakaoLoginBtn type={"default"} />
                                {/* <TempLoginDialog type={ "default"}/> */}
                                </li>
                    

                        </ul>

                        <div
                        style={{
                            position: "absolute",
                            bottom:`calc(90px + 16px)`,
                            // backgroundColor:"red",
                            height:"50px",
                            fontSize:"20px",
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center",
                            color:"white"
                        }}
                        >
                            <img
                            width={"80%"}
                            style={{
                                aspectRatio:"auto 1/1"
                            }}
                            src={LoginTxtLg}/>
                        </div>
                        <CustomFooter  />
                        </div>
                    </Fade>
                </Box>
            </PC>
        </div>
  
    );
}

export default LoginPage;