// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { GoogleAuthProvider, getAuth } from "firebase/auth";
// import { getFirestore } from "firebase/firestore";
// import { getStorage } from "firebase/storage";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   // apiKey: "AIzaSyC-zpr8XA6vSBU9VPoSod1tMsy7tfTPSNQ",
//   // authDomain: "nomad-pj.firebaseapp.com",
//   // projectId: "nomad-pj",
//   // storageBucket: "nomad-pj.appspot.com",
//   // messagingSenderId: "806424511923",
//   // appId: "1:806424511923:web:1d31267ebe84b6c22437c6"
//   apiKey: "AIzaSyBYqWOU0x2ZUEmZDXdPi9Hnmc5PWqJf1O0",
//   authDomain: "nomad-pj-ff717.firebaseapp.com",
//   projectId: "nomad-pj-ff717",
//   storageBucket: "nomad-pj-ff717.appspot.com",
//   messagingSenderId: "166172375022",
//   appId: "1:166172375022:web:366a5baf2900b7799480ff",
//   measurementId: "G-L30CCP1J2V"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// export const db=getFirestore(app)
// export const storage = getStorage(app)
// export const auth = getAuth(app);
// export const provider = new GoogleAuthProvider();
///ara
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { GoogleAuthProvider,getAuth, signInWithRedirect } from "firebase/auth";
// import { GoogleAuthProvider } from "firebase/auth/cordova";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBYqWOU0x2ZUEmZDXdPi9Hnmc5PWqJf1O0",
  authDomain: "nomad-pj-ff717.firebaseapp.com",
  // authDomain: "/google-info",
  projectId: "nomad-pj-ff717",
  storageBucket: "nomad-pj-ff717.appspot.com",
  messagingSenderId: "166172375022",
  appId: "1:166172375022:web:366a5baf2900b7799480ff",
  measurementId: "G-L30CCP1J2V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db=getFirestore(app)
export const storage = getStorage(app)
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const signInWithGoogle = async() =>{
  
  const res=await signInWithRedirect(auth,provider)
}
export const signOut = () => auth.signOut()