import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StarImg from '../images/star_icon.png'
const { Kakao } = window;

function GoAnswerPageBtn({width,title="답변 작성하러 가기",fontSize="18px"}) {
    const [hover, setHover] = useState(false)
    const navigate=useNavigate()
    const onMouseEnter = () => {
        setHover(true)
    }
    const onMouseLeave = () => {
        setHover(false)
    }

   const goAnswerPg=()=>{
    navigate("/answer/:0",{
        state:{
            answerNum:0
        }
    })
   }
    return (
        <button
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                style={{
                    display:"flex",
                    gap:"16px",
                    justifyContent:"center",
                    alignItems:"center",
                    cursor: "pointer",
                    backgroundColor: hover ? "rgba(255,255,255, 0.3)" : "rgba(255,255,255, 0)",
                    color: "white",
                    border: "3px solid white",
                    width: width,
                    height: "58px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    fontSize: fontSize,
                    borderRadius: "50px",
                    fontWeight: "bold",
                    zIndex:999
                }}
                onClick={goAnswerPg}
            >
     <img src={StarImg}/>
     <span>{title}</span>      
        </button>
    );
}

export default GoAnswerPageBtn;