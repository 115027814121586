import { useEffect, useMemo, useState } from "react";
import CustomAppbar from "../components/CustomAppbar";
import { Outlet, useLocation } from "react-router-dom";

import { useRecoilState } from "recoil";
import { themeState } from "../atom/atom";
import { getDesignTokens } from "../const/const";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline, Dialog, DialogContent, createTheme } from "@mui/material";
import CustomNavBar from "../components/CustomNavBar";
import CustomFooter from "../components/CustomFooter";
export default function CustomLayout() {
  const location=useLocation()
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const handleResize = () => {
      setInnerWidth(window.innerWidth);


  };
  useEffect(()=>{
    window.addEventListener("resize", handleResize);

    return () => {
        // cleanup
        window.removeEventListener("resize", handleResize);
    };
  },[])

  const [mode, setMode] = useRecoilState(themeState);
  const colorMode =useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode) =>
          prevMode === 'light' ? 'dark' : 'light',
        );
      },
    }),
    [],
  );

  // Update the theme only if the mode changes
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode])
  return (
    <div className="App"
       >
 
           <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <CustomAppbar toggleColorMode={colorMode.toggleColorMode}/> */}
     
          <Outlet />
      {/* <CustomNavBar/> */}
      <CustomFooter  />

       </ThemeProvider>
    
    </div>
  );
}

