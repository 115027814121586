export const appBarColor="#151B33"
export const bgColor="#161527"
export const getDesignTokens = (mode) => ({
    palette: {
      mode,
      // ...(mode === 'light'
      //   ? {
      //       // palette values for light mode
      //       primary: amber,
      //       divider: amber[200],
      //       text: {
      //         primary: grey[900],
      //         secondary: grey[800],
      //       },
      //     }
      //   : {
      //       // palette values for dark mode
      //       primary: deepOrange,
      //       divider: deepOrange[700],
      //       background: {
      //         default: deepOrange[900],
      //         paper: deepOrange[900],
      //       },
      //       text: {
      //         primary: '#fff',
      //         secondary: grey[500],
      //       },
      //     }),
    },
  });
  export const preNotiMsg=<div
  style={{
    display:"flex",
    flexDirection:"column"
  }}
  >
<p>
<div>본 테스트 페이지는</div>
<div
style={{
  textDecoration:"underline"
}}
>주관식 답변으로</div>
<div
style={{
  textDecoration:"underline"
}}
>이루어져 있습니다</div>
  </p>
  <p>
  <div>급변하는 디지털 세상 속</div>
  <div>우리는 자기성찰과</div>
  <div>창의적인 사고로</div>
  <div>나아가야 합니다</div>
  </p>

  <p
  style={{
    textDecoration:"underline"
  }}
  >
  <div>주관식 답변은</div>
  <div>나의 생각과 감정을</div>
  <div>깊이 탐색하고</div>
  <div>창의성을 이끌어 내는데</div>
  <div>중요한 역할을 합니다</div>
  </p>
  <p
  style={{
    fontStyle: "italic"
  }}
  >
  <div>정확하게 작성할수록</div>
  <div>결과에 도움이 됩니다.</div>
  </p>

  </div>
    export const preNotiTitle=
    <div
    style={{
      display:"flex",
      flexDirection:"column",
      // fontSize:"18px"
    }}
    ><span>테스트전 아래내용</span><span>필독후 진행해주세요!!!</span></div>
    ;