import { Alert, Box, Fade, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { bgColor, preNotiMsg, preNotiTitle } from '../const/const';
import GoAnswerPageBtn from '../components/GoAnswerPageBtn';
import EmptyBg from '../images/empty-wide-bg.png'
import WideEmptyBg from '../images/empty-wide-bg.png'
import StarIcon from '../images/star_icon.png'
import { Mobile, PC } from '../components/ReactiveLayout';
function PreNotiPage(props) {
    const [innerHeight, setInnerHeight] = useState(window.innerHeight);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const handleResize = () => {
        setInnerHeight(window.innerHeight);
        setInnerWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            // cleanup
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
      <div>
          <Mobile>

          <Box sx={{}}>
                <Fade in={true} timeout={1300} style={{}}>
                    <Paper
                        sx={{
                            margin: 0,
                            padding: 0,
                            backgroundColor: "transparent"
                        }}
                    > 
                          <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                         
                                height:`calc(${innerHeight}px - 90px)`,
                                overflowY:"scroll",
                                 paddingTop:"16px"
                                    }}
                                >  
                                 <img
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: `calc(${innerWidth} / 2)`,
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover" // 이미지 비율 유지를 위해 추가
                                }}
                               src={WideEmptyBg}
                            />                           
                           <div
                            className='glass'
                            style={{
                                border:"3px solid white",
                                
                                paddingTop:"16px",
                                paddingBottom:"16px",
                                display:"flex",
                                flexDirection:"row",
                                alignItems:"center",
                                // textAlign:"center",
                                justifyContent:"space-evenly",
                                fontWeight:700,
                                fontSize:"20px",
                                color:"white",
                                width:"300px",
                                height:"100px",
                                zIndex:999
                            }}
                            > <img src={StarIcon} style={{
                                paddingBottom:"8px"
                            }}/>
                            <>{preNotiTitle}</>
                            <img src={StarIcon} style={{
                                paddingBottom:"8px"
                            }}/>
                            </div>
                                <div
                                style={{
                                    height:`calc(${innerHeight}px - 90px - 58px - 16px - 100px - 16px - 16px)`,
                                    color: "white",
                                    width:"300px",
                                    zIndex:999,
                                    fontSize:"20px",
                                    fontWeight:700,
                                overflowY:"scroll"
                                    // textAlign:"start"
                                }}>
                                {preNotiMsg}
                                </div>  
                           <div
                           style={{
                            position:"absolute",
                            bottom:"106px"
                           }}
                           ><GoAnswerPageBtn
                                fontSize="20px"
                                width={"300px"}/></div>
                                
                             </div>
                            

                    </Paper>
                </Fade>
            </Box>
          </Mobile>
          <PC>
          <Box sx={{}}>
                <Fade in={true} timeout={1300} style={{}}>
                    <Paper
                        sx={{
                            margin: 0,
                            padding: 0,
                            backgroundColor: "transparent"
                        }}
                    > 
                          <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                justifyContent:"space-evenly",
                                height:`calc(${innerHeight}px - 90px)`
                                    }}
                                >  
                                 <img
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: `calc(${innerWidth} / 2)`,
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover" // 이미지 비율 유지를 위해 추가
                                }}
                               src={WideEmptyBg}
                            /> 
                              
                              <div
                            className='glass'
                            style={{
                                border:"3px solid white",
                                
                                paddingTop:"16px",
                                paddingBottom:"16px",
                                display:"flex",
                                flexDirection:"row",
                                alignItems:"center",
                                // textAlign:"center",
                                justifyContent:"space-evenly",
                                fontWeight:700,
                                fontSize:"18px",
                                color:"white",
                                width:innerWidth <= 800?"360px":"500px",
                                zIndex:999
                                //glass effect
                                /* From https://css.glass */

                            }}
                            > <img src={StarIcon} style={{
                                paddingBottom:"8px"
                            }}/>
                            <>{preNotiTitle}</>
                            <img src={StarIcon} style={{
                                paddingBottom:"8px"
                            }}/>
                            </div>
                                <div
                                style={{
                                    color: "white",
                                    width:innerWidth <= 800?"360px":"500px",
                                    zIndex:999,
                                    fontSize:"18px",
                                    fontWeight:700,
                                }}>
                                {preNotiMsg}
                                </div>  
                         
                                <GoAnswerPageBtn width={innerWidth <= 800?"360px":"500px"}/>
                             </div>
                            

                    </Paper>
                </Fade>
            </Box>

          </PC>
      </div>
    );
}

export default PreNotiPage;