import React, { useEffect, useState } from 'react';
import { storage } from '../service/firebase';
import { getDownloadURL, ref } from 'firebase/storage';
import StarImg from '../images/star_icon.png'
const { Kakao } = window;

function ShareBtn({width}) {
    const [hover, setHover] = useState(false)
    const jsKey='c1b71d1830f20299a67f94a767f5c472'
    const [mainUrl,setMainUrl]=useState("")
    const getMainImgUrl=async()=>{
        try{
            const newURL = "/thumbnails/main.png";
            const url = await getDownloadURL(ref(storage, newURL));
        
            // const response = await fetch(url);
            // const blob = await response.blob();
            // const imageURL = URL.createObjectURL(blob);
            // console.log("공유하기 썸네일>>>",url)
            setMainUrl(url)
            return url;
        
          } catch (error) {
            console.error("Error loading image:", error);
          }
        
    }
    const onMouseEnter = () => {
        setHover(true)
    }
    const onMouseLeave = () => {
        setHover(false)
    }
    useEffect(()=>{ 
    getMainImgUrl()
   
    	// init 해주기 전에 clean up 을 해준다.
        Kakao.cleanup();
        // 자신의 js 키를 넣어준다.
        Kakao.init(jsKey);
        // 잘 적용되면 true 를 뱉는다.
       
    },[]);
    const onShare=()=>{
        Kakao.Share.sendDefault({
                objectType: 'feed',
                content: {
                    title: '디지털노마드 직업군 테스트',
                    description: '당신의 특별함 & 다능함을 찾아드려요',
                    
                    imageUrl:mainUrl,
                    link: {
                        mobileWebUrl: "https://dino2024.com"//window.location.href,
                    },
                },
                buttons: [
                    {
                        title: '나도 테스트 하러가기',
                        link: {
                        mobileWebUrl: "https://dino2024.com"//window.location.href,
                        },
                    },
                    ],
                });
        

    }
    return (
        <button
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                style={{
                    
                    cursor: "pointer",
                    backgroundColor: hover ? "rgba(255,255,255, 0.3)" : "rgba(255,255,255, 0)",
                    color: "white",
                    border: "3px solid white",
                    width: width,
                    height: "58px",
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    fontSize: "16px",
                    borderRadius: "50px",
                    fontWeight: "bold",
                    display:"flex",
                    gap:"16px",
                    justifyContent:"center",
                    alignItems:"center",
                }}
                onClick={onShare}
            >
            <img src={StarImg}/>    
            <span>카카오톡공유하기</span>
        </button>
    );
}

export default ShareBtn;