
import axios from 'axios';

// const baseURL = 'http://18.234.84.95:5000'; // 백엔드 서버 주소
// const baseURL = '54.234.215.181:5000'; // 백엔드 서버 주소

const instance = axios.create({
  // baseURL,
  responseType:"json"
});

export default instance;