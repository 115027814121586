import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { authTest, nowUserState } from '../atom/atom';
import KakaoIcon from '../images/kako-icon.png';
import KakaoLogin from "react-kakao-login";
import instance from '../service/api';

function KakaoLoginBtn({ onClick, type }) {
    const [test, setTest] = useRecoilState(authTest);
    const navigate = useNavigate();
    const [hover, setHover] = useState(false)

    const [userInfo, setUserInfo] = useRecoilState(nowUserState);
    const jsKey = 'c1b71d1830f20299a67f94a767f5c472';
    const redirectUri = `${window.location.origin}/oauth/callback`;
    const scope = [
        "profile_nickname",
        "profile_image",
        "talk_plusfriends"
    ].join(",");

    const onMouseEnter = () => {
        setHover(true);
    }

    const onMouseLeave = () => {
        setHover(false);
    }

    const getKaKaoUserData = async token => {
        const kakaoUser = await instance.get(`https://kapi.kakao.com/v2/user/me`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        alert(kakaoUser.data);
        return kakaoUser.data;
    }

    const kakaoOnSuccess = async (data) => {
      if (!window.Kakao.isInitialized()) {
          
        window.Kakao.init(jsKey);
    } 
        const idToken = data.response.access_token;
        const name = data.profile.kakao_account.profile.nickname;
        const email = data.profile.kakao_account.email;
        const birthyear = data.profile.kakao_account.birthyear;
        const birthday = data.profile.kakao_account.birthday;
        const phone_number = data.profile.kakao_account.phone_number;
        localStorage.setItem('accessToken',idToken)
        
        const newObj = {
            type: "kakao",
            name: name,
            birth: birthyear + birthday,
            phone_number: phone_number,
            email: email
        };
        localStorage.setItem("authInfo",JSON.stringify(newObj))//location.state.authInfo;
        navigate("/kakao-info",{
          state:{newObj:newObj}
      })
        localStorage.setItem('nowUserIdInLocal', email);
 
    }

    const kakaoOnFailure = (error) => {
        console.log(error);
    };

    const smallStyle = {
      textIndent: "-9999px",          
        cursor: "pointer",
        backgroundColor: hover ? "rgba(255,255,255, 0.3)" : "rgba(255,255,255, 0)",
        color: "white",
        border: "3px solid rgba(255,255,255, 0.6)",
        width:"60px" ,
        height: "60px", 
        fontSize: "18px",
        borderRadius: "50px",
        fontWeight: "bold",
        alignItems: "center",
        justifyContent: "center"
    };

    const defaultStyle = {
      margin:0,
      padding:0,
        cursor: "pointer",
        backgroundColor: hover ? "rgba(255,255,255, 0.3)" : "rgba(255,255,255, 0)",
        color: "white",
        border: "3px solid rgba(255,255,255, 0.6)",
        width:"200px",
        height:  "50px",
        fontSize: "18px",
        borderRadius: "50px",
        fontWeight: "bold",
    };
const getBtnContent=(type)=>{
  if (type==="small") {
    return <div
    style={{
      width: "40px",
      height: "40px",
      display: "flex",
      alignItems: "center",
      borderRadius: "50%",
      justifyContent: "center",
      backgroundColor: "rgba(255,255,255, 0.3)"
    }}
><img width="20px" height="20px" src={KakaoIcon} /></div>
  } else {
    return  <div
    style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        paddingRight: "8px"
    }}
>
    <div
        style={{
            width: "32px",
            height: "32px",
            display: "flex",
            alignItems: "center",
            borderRadius: "50%",
            justifyContent: "center",
            backgroundColor: "rgba(255,255,255, 0.3)"
        }}
    ><img width="20px" height="20px" src={KakaoIcon} /></div>
 {  <span
    style={{
        fontSize:"12px",
        opacity:0.8
        // backgroundColor:"red"

    }}
    >카카오 로그인</span>}</div>
  }
}
    return (
      <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ><KakaoLogin
key={jsKey}
      
            // className="kakao-login-button"
            style={type === "small" ? smallStyle : defaultStyle}
            token={jsKey}
            scopes={scope}
            onSuccess={kakaoOnSuccess}
            onFail={kakaoOnFailure}
        > {getBtnContent(type)}</KakaoLogin></div>

        
    );
}

export default KakaoLoginBtn;
