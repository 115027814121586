import { Alert, Box, Fade, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { bgColor, preNotiMsg } from '../const/const';
import GoAnswerPageBtn from '../components/GoAnswerPageBtn';
import EmptyBg from '../images/empty-wide-bg.png'
import WideEmptyBg from '../images/empty-wide-bg.png'
import { useLocation, useNavigate } from 'react-router-dom';
import GoAnswerGoogle from '../components/GoAnswerGoogle';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../service/firebase';
import StarIcon from '../images/star_icon.png'
import NotiSrc from '../images/nomad-noti.png'
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FaBirthdayCake } from 'react-icons/fa';
function KakaoInfoPage(props) {
    const location = useLocation();
    const [innerHeight, setInnerHeight] = useState(window.innerHeight);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    //new obj말고 localStorage.getItem("authInfo") 하자
    const newObj =JSON.parse(localStorage.getItem("authInfo"))// location.state.newObj;
    const phone = newObj.phone_number;
    const [phoneNum, setPhoneNum] = useState("");
    const [birth, setBirth] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const handleResize = () => {
        setInnerHeight(window.innerHeight);
        setInnerWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        if (phone === null) {
            setPhoneNum("")
        } else {
            setPhoneNum(phone)
        }
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const validatePhoneNumber = (phone) => {
        const phoneRegex = /^\d{3}-\d{3,4}-\d{4}$/;
        return phoneRegex.test(phone);
    };

    const validateBirthDate = (birth) => {
        const birthRegex = /^\d{4}\d{2}\d{2}$/;
        return birthRegex.test(birth);
    };
    const formatPhoneNumber=(phoneNumber)=> {
        // 공백을 제거하고 +82 국가 코드를 제거합니다.
        const cleanedNumber = phoneNumber.replace(/\+82\s*/, '');
    
        // 국가 코드가 제거된 번호가 10으로 시작하지 않는 경우 0을 추가합니다.
        const formattedNumber = cleanedNumber.replace(/^10/, '010');
    
        return formattedNumber;
    }
    const setDocu = async () => {
        if (!validateBirthDate(birth)) {
            window.confirm("올바른 형식의 생년월일을 입력해 주세요.");
            return;
        } else {
            localStorage.setItem('nowUserIdInLocal', newObj.email);
            sessionStorage.setItem('nowLogin', "true");
            const phoneRes = newObj.phone_number;
    
            const userDocRef = doc(db, "userInfo", newObj.email);
    
            // Firestore에 문서가 존재하는지 확인합니다.
            const docSnap = await getDoc(userDocRef);
    
            if (docSnap.exists()) {
                // 문서가 존재하면 업데이트
                await setDoc(userDocRef, {
                    type: "kakao",
                    name: newObj.name,
                    birth: birth,
                    phone_number: phoneRes,
                    email: newObj.email
                }, { merge: true }); // 기존 데이터를 유지하고 병합
            } else {
                // 문서가 존재하지 않으면 새로 생성
                await setDoc(userDocRef, {
                    type: "kakao",
                    name: newObj.name,
                    birth: birth,
                    phone_number: phoneRes,
                    email: newObj.email
                });
            }
    
            navigate("/pre-noti");
        }
    };
    const handlePhoneNumberChange = (e) => {
        let input = e.target.value.replace(/\D/g, '');
        let formattedNumber;

        if (input.length > 3 && input.length <= 7) {
            formattedNumber = `${input.slice(0, 3)}-${input.slice(3)}`;
        } else if (input.length > 7) {
            formattedNumber = `${input.slice(0, 3)}-${input.slice(3, 7)}-${input.slice(7, 11)}`;
        } else {
            formattedNumber = input;
        }

        setPhoneNum(formattedNumber);
    };

    const handleBirthdayChange = (e) => {
        let input = e.target.value.replace(/\D/g, '');
        let formattedBirthday = input.slice(0, 8);

        setBirth(formattedBirthday);
    };
    return (
        <div
            style={{
                
                display: 'flex',
                width: "100%",
                height: '100%',
                alignItems: "flex-start",
                justifyContent: "center",
                backgroundColor: bgColor,
                
                // paddingTop:"16px",
                // paddingBottom:"16px",
            }}
        >
            <Box sx={{}}>
                <Fade in={true} timeout={1300} style={{}}>
                    <Paper
                        sx={{
                            margin: 0,
                            padding: 0,
                            backgroundColor: "transparent"
                        }}
                    >   <div
                    style={{overflow:"hidden",
                        display: 'flex',
                        width: "100%",
                        height: '100%',
                        alignItems: "flex-start",
                        justifyContent: "center",
                        backgroundColor: bgColor,
                        // paddingTop:"16px",
                        // paddingBottom:"16px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100vh",
                            // paddingTop: "53px",
                            gap: "40px",
                        }}
                    >
                          <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        width: innerWidth,
                                        height: innerHeight,
                                  
                                        // paddingTop: "53px",
                                        gap: "40px",
                                    }}
                                >  
                                 <img
                                 height={innerHeight}
                                 width={innerWidth}
                                style={{
                                    position: "absolute"
                                }}
                                 src={WideEmptyBg}
                            /> 
                               <div
                             className='glass'
                             style={{
                                width:innerWidth <= 800?"340px":"467px",
                                fontSize:"18px",
                                padding:"8px",
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                                flexDirection:"row",
                                gap:"8px",
                                color:"white",
                                marginTop:"50px"
                             }}
                             >
                              <img src={StarIcon} style={{
                             
                            }}/><span>추가 정보를 입력 해 주세요</span>
                            <img src={StarIcon} style={{
                             
                            }}/>
                                </div>   
                            <div
                            style={{
                                display:"flex",
                                
                                flexDirection:"column",
                                alignItems:"center",
                               
                                // backgroundColor:"red",
                                zIndex:999,
                                color:"white",
                                // height:"100%",
                                width:innerWidth <= 800?"360px":"800px",
                                // paddingTop:"56px",//innerHeight/2,
                                gap:"16px"
                            }}
                            > 
                          
                                
                        
                            <div
                            style={{
                                display:"flex",
                                flexDirection:"row",
                                // gap:"8px",
                                alignItems:"center"
                              
                            }}
                            >
                                <div
                                style={{
                                    width:"130px",
                                    display:"flex",
                                    flexDirection:"row",
                                    gap:"8px"
                                }}
                                >
                                    <FaBirthdayCake size={18}/>
                                    <span>생년 월일</span>
                                   </div>
                                <div
                                style={{
                                    width:"8px"
                                }}
                                />
                                <TextField
                                placeholder='19910120'
                                value={birth}
                                inputProps={{
                                    style:{
                                        backgroundColor: "transparent",
                                        color: "white",
                                        border: "3px solid white",
                                        outline: "none",
                                        width:innerWidth <= 800?"160px":"300px",
                                    }
                                }}
                                sx={{
                              
                                }}
                                onChange={handleBirthdayChange}
                                variant='filled'
                                size='small'/>
                            </div>
                            </div>
                            
                          
                                <GoAnswerGoogle
                                // title=''
                                setDocu={setDocu}
                                width={innerWidth <= 800?"300px":"467px"}/>
                                  <div
                             style={{
                                zIndex:999,
                                width:innerWidth <= 800?"300px":"467px"
                             }}
                             >
                                <img
                                width={"100%"}
                                src={NotiSrc}
                                />
                             </div>
                             </div>
                           
                             </div>
                             </div>

                    </Paper>
                </Fade>
            </Box>
        </div>
    );
}

export default KakaoInfoPage;