
import React from 'react';

function CustomFooter({innerWidth="100%",type="mobile"}) {
    return (
        <div
        style={{
        
            position: "absolute",
            left: 0,
            bottom: 0,
            width: innerWidth,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",borderTop: "2px solid white",
        
        }}
        >
        <ul
            style={{
                width: innerWidth <= 800 ? "381px" : "1329px",
                //  backgroundColor:"white",
                height: "90px",
                margin: 0,
                padding: "8px",
                // paddingLeft:"100px",
                listStyle: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                textAlign: "left",
                fontSize: "11px",
                gap: "3px",
                
                color: "white"
        
        
            }}
        >
            <li>회사명 : 디엠컴퍼니 | 대표이사 : 김미수 | 카카오톡채널:디지털다능인</li>
            <li></li>
            <li>주소 : 서울특별시 서초구 사임당로8길 13, 402동 4층 J123호(서초동, 제일빌딩)</li>
            <li>사업자 등록번호 : 187-40-01020</li>
        
        </ul>
        
        </div>
    );
}

export default CustomFooter;