import React, { useState } from 'react';
import { FaArrowRight } from "react-icons/fa6";
import StarImg from '../images/star_icon.png'
function AnswerBtn({width,title,onAnswer,type}) {
    const [hover,setHover]=useState(false)
    const onEnter=()=>{
setHover(true)

    }
    const onLeave=()=>{
        setHover(false)
    }
    return (
        <button
        className='hoverBtn'
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
        onClick={onAnswer}
        style={{
        cursor: "pointer",
                    backgroundColor: hover ? "rgba(255,255,255, 0.3)" : "rgba(255,255,255, 0)",
                    color: "white",
                    border: "3px solid white",
                    width: width,
                    height: "48px",
                    display:"flex",
                    gap:"16px",
                    justifyContent:"center",
                    alignItems:"center",
                    // paddingLeft: "50px",
                    // paddingRight: "50px",
                    fontSize: type==="mobile"?"20px":"18px",
                    borderRadius: "50px",
                    fontWeight: "bold",
            
        }}
        > 
        {/* <FaArrowRight
        size={20}
        style={{opacity:0}}
        /> */}
       <img src={StarImg}/>
            <span>{title}</span>
           {/* <img src={StarImg} style={{opacity:0}}/> */}
            {/* <FaArrowRight
            size={20}
            /> */}
        </button>
    );
}

export default AnswerBtn;