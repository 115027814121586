import { useScrollTrigger } from '@mui/material';
import React, { useState } from 'react';
import StarImg from '../images/star_icon.png'
function SaveImgBtn({onClick,width}) {
    const [hover,setHover]=useState(false)
    const onMoueEnter=()=>{
        setHover(true)
    }
    const onMoueLeave=()=>{
        setHover(false)
    }
    return (
        <button
        onMoueEnter={onMoueEnter}
onMoueLeave={onMoueLeave}
                            style={{
                    
                                cursor: "pointer",
                                backgroundColor: hover ? "rgba(255,255,255, 0.3)" : "rgba(255,255,255, 0)",
                                color: "white",
                                border: "3px solid white",
                                width: width,
                                height: "58px",
                                paddingLeft: "50px",
                                paddingRight: "50px",
                                fontSize: "16px",
                                borderRadius: "50px",
                                fontWeight: "bold",
                                display:"flex",
                                gap:"16px",
                                justifyContent:"center",
                                alignItems:"center",
                            }}
                     onClick={onClick}
                            >
                                  <img src={StarImg}/>    
                                <span>테스트결과저장하기</span></button>
    );
}

export default SaveImgBtn;